<template>
  <div class="sidebar-main">
    <sidebar-main-item
      class="sidebar-main-menu-item"
      v-for="item in menu_list.filter((item) => !item.meta.hidden)"
      :key="item.name"
      :path="item.path"
      :text="item.meta.title"
      :icon="item.meta.icon"
      :selected="$route.name === item.name || $route.meta.rootName === item.name"
      @click.native="routeChangeHandler(item.name)"/>
  </div>
</template>

<script>
import SidebarMainItem from './SidebarMainItem.vue';

export default {
  name: 'sidebarMain',
  props: {
    menu_list: Array,
  },
  components: { SidebarMainItem },
  methods: {
    routeChangeHandler(routeName) {
      const route = {
        name: routeName,
      };
      if (this.$route.name !== routeName) {
        this.$router.push(route);
        console.log('z', route);
      }
    },
  },
};
</script>

<style scoped lang="less">
  .sidebar-main {
    padding: 16px 8px;
    .sidebar-main-menu-item {
      margin-bottom: 6px;
    }
  }
</style>
