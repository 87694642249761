<template>
  <div>
    <div class="sidebar-main-item sidebar-main-item-all" :class="{ 'selected': selected }" v-show="$store.state.sidebar_is_expanded">
      <a
        class="sidebar-item"
        :href="goNewTarget(path)"
        @click.prevent="emptyFn()">
        <img class="item-icon" :src="icon" alt="menu icon">
        <span class="item-text">{{ text }}</span>
      </a>
    </div>
    <div class="sidebar-main-item" :class="{ 'selected': selected }" v-show="!$store.state.sidebar_is_expanded">
      <a
        class="sidebar-item"
        :href="goNewTarget(path)"
        @click.prevent="emptyFn()">
        <Tooltip :content="text" placement="right" offset="0 12" transfer>
          <img class="item-icon" :src="icon" alt="menu icon">
        </Tooltip>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SidebarMainItem',
    props: {
      icon: String,
      text: String,
      path: String,
      selected: Boolean,
    },
    methods: {
      goNewTarget(path) {
        if (path) {
          const targetPath = this.$tools.routeParamParser(path, this.$route.params);
          return targetPath;
        }
        return '#';
      },
      emptyFn() {},
    },
  };
</script>

<style scoped lang="less">
.sidebar-main-item-all{
  width: 193px !important;
}
  .sidebar-main-item {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: transparent;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    transition: background-color ease-in-out 0.2s;
    &:hover {
      background-color: rgba(41, 120, 255, 0.2);
    }
    .sidebar-item{
      display: block;
      line-height: 16px;
      padding: 9px 14px;
      .item-icon, .item-text {
        vertical-align: middle;
      }
      .item-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      .item-text {
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
      }
    }

  }
  .selected {
    background-color: @basicColor;
    &:hover {
      background-color: @basicColor;
    }
  }
</style>
