<template>
  <div class="app-home-layout">
    <div class="aside" :class="{'unexpanded-sidebar-wrap': !$store.state.sidebar_is_expanded}">
      <div class="aside-header">
        <router-link :to="{ name: 'AppList' }">
          <img src="../assets/image/logo@2x.png" alt="logo" class="logo" v-show="$store.state.sidebar_is_expanded"/>
          <div style="margin: 0 auto">
            <img src="../assets/image/ic_sidebar_expand@2x.png"
                 class="expand"
                 :class="$store.state.sidebar_is_expanded ? '' : 'transform'"
                 @click="toggleSidebar"/>
          </div>
        </router-link>
      </div>
      <div class="aside-menu">
        <sidebar-main :menu_list="route_list"/>
      </div>
    </div>
    <div class="main">
      <div class="header">
        <div class="left-box">
          <div v-if="show_app_select" class="app-select-box">
            <Select v-model="selected_app_id" class="app-select" :disabled="app_select_disabled">
              <img class="selected-app-logo" slot="prefix" :src="app_logo_map.get(selected_app_id)" alt="app logo">
              <Option class="input-option" value="" disabled>
                <Input ref="selectInput" v-model="search_content" @click.native="inputClickHandler"/>
              </Option>
              <Option v-for="item in filtered_app_list" :key="item.app_id" :value="item.app_id" :label="item.app_name">
                <img class="app-logo" :src="item.logo" alt="app logo">
                <span class="app-text">{{ item.app_name }}</span>
              </Option>
            </Select>
          </div>
        </div>
        <div class="right-box">
          <Dropdown placement="bottom" transfer @on-click="dropdownClickHandler">
            <div class="user-info">
              <img class="user-avatar" :src="userAvatar" alt="User Avatar">
              <span class="user-name">{{ userName }}</span>
            </div>
            <DropdownMenu slot="list">
              <DropdownItem v-if="$store.getters['userStore/isAdmin']" name="config">设置</DropdownItem>
              <DropdownItem name="exit">退出登录</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import { redirectToLogin } from 'dc-vue-sso';
import configRoute from '@/router/authorityConfigRoute';
import SidebarMain from '../components/page/sidebar/SidebarMain.vue';
import mainRoute from '../router/mainRoute';
import appDetailRoute from '../router/appDetailRoute';

const APP_OVERVIEW = 'appOverview';
const APP_DETAIL = 'appDetail';
const AUTHORITY_CONFIG = 'authorityConfig';

export default {
  name: 'AppHomeLayout',
  components: { SidebarMain },
  data() {
    return {
      selected_app_id: null,
      search_content: '',
    };
  },
  computed: {
    show_app_select() {
      // 判断是否显示app select的关键参数
      const { level } = this.$route.meta;
      return level === 'appDetail';
    },
    app_list() {
      return this.$store.state.app_list;
    },
    userName() {
      return this.$store.state.userStore.ssoUserName;
    },
    userAvatar() {
      return this.$store.state.userStore.ssoUserAvatar;
    },
    filtered_app_list() {
      if (this.search_content === '') {
        return this.app_list;
      }
      const filteredArr = this.app_list.filter((app) => app.app_name.toLowerCase().indexOf(this.search_content.toLowerCase()) !== -1);
      if (!filteredArr.find((item) => item.app_id === this.selected_app_id)) {
        const targetItem = this.app_list.find((item) => item.app_id === this.selected_app_id);
        filteredArr.unshift(targetItem);
      }
      return filteredArr;
    },
    app_logo_map() {
      const arr = this.app_list.map((app) => ([app.app_id, app.logo]));
      return new Map(arr);
    },
    route_list() {
      const { level } = this.$route.meta;
      if (level === APP_OVERVIEW) {
        console.log('a', mainRoute);
        return mainRoute;
      } if (level === APP_DETAIL) {
        console.log('b', appDetailRoute);
        return appDetailRoute;
      }
      if (level === AUTHORITY_CONFIG) {
        return configRoute;
      }
      return [];
    },
    app_select_disabled() {
      return (this.$route.name !== 'Push' && this.$route.name !== 'Template' && this.$route.name !== 'DataAnalysis' && this.$route.name !== 'CreatePush');
    },
  },
  methods: {
    inputClickHandler() {
      this.$refs.selectInput.focus();
    },
    dropdownClickHandler(name) {
      if (name === 'exit') {
        this.exit();
      } else if (name === 'config') {
        this.config();
      }
    },
    exit() {
      redirectToLogin(true);
    },
    config() {
      this.$router.push({ name: 'AuthorityConfig' });
    },
    toggleSidebar() {
      const isExpanded = !this.$store.state.sidebar_is_expanded;
      this.$store.commit('setSidebarIsExpanded', {
        sidebar_is_expanded: isExpanded,
      });
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        const appId = newVal.params.app_id;
        if (appId) {
          this.selected_app_id = appId;
        }
      },
      immediate: true,
    },
    selected_app_id(newVal) {
      const route = this.$route;
      const { params } = route;
      params.app_id = newVal;
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="less">
  @asideWidth: 207px;
  @headerHeight: 48px;
  @contentSidePadding: 16px;
  .app-home-layout {
    display: flex;
    width: 100vw;
    height: 100vh;
    .aside {
      width: 207px;
      height: 100%;
      transition: width 0.2s ease-in-out;
      background-color: @deepBackgroundColor;
      .aside-header {
        height: @headerHeight;
        line-height: @headerHeight;
        border-bottom: 1px solid #27272E;
        text-align: left;
        margin: 0 16px;
        .logo {
          width: 113px;
          height: 32px;
          margin-right: 20px;
          vertical-align: middle;
        }
        .expand{
          width: 16px;
          height: 16px;
          cursor: pointer;
          transform: rotate(0deg);
          transition: transform 0.2s ease;
          vertical-align: middle;
        }
        .transform{
          transform: rotate(180deg);
          //transition: transform 0.2s ease;
          margin: 0 auto;
        }
      }
      .aside-menu {
        height: calc(~'100% - @{headerHeight}');
        overflow: auto;
      }
    }
    .unexpanded-sidebar-wrap {
      width: 60px;
    }
    .main {
      flex: 1 1 auto;
      min-width: 1012px;
      height: 100%;
      overflow-x: scroll;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: @headerHeight;
        padding: 0 16px;
        background: #FFFFFF;
        box-shadow: inset 0px -1px 0px 0px #EAEBF7;
        .left-box {
          .app-select-box {
            .input-option {
              position: sticky;
              top: -5px;
              background-color: #FFFFFF;
              border-bottom: 1px solid #EAEBF7;
              box-shadow: 0 1px 2px 0px #eeeffb;
              z-index: 9999;
            }
            /deep/ .ivu-select-disabled .ivu-select-selection {
              background-color: #FFFFFF;
            }
            /deep/ .ivu-select-selection {
              border: none;
              box-shadow: none;
            }
            /deep/ .ivu-select-dropdown {
              max-height: 300px;
            }
            .selected-app-logo {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              margin-right: 6px;
              vertical-align: middle;
            }
            /deep/ .ivu-select-selected-value {
              margin-right: 4px;
              font-size: 16px!important;
              font-weight: 600!important;
              color: #202444!important;
              vertical-align: middle!important;
            }
            .app-logo {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              margin-right: 6px;
              vertical-align: middle;
            }
            .app-text {
              font-size: 14px;
              font-weight: 400;
              /*color: #202444;*/
              line-height: 22px;
              vertical-align: middle;
            }
          }
        }
        .right-box {
          .user-info {
            line-height: @headerHeight;
            cursor: pointer;
            .user-name, .user-avatar {
              vertical-align: middle;
            }
            .user-name {
              margin-left: 8px;
            }
            .user-avatar {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }
        }
      }
      .content {
        height: calc(~'100% - @{headerHeight}');
        padding: 0 @contentSidePadding @contentSidePadding;
        background-color: @contentBackgroundColor;
        overflow: auto;
      }
    }
  }
  /deep/ .router-link-active{
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
